import { useMutation } from "@tanstack/react-query";
import { doFeedbackMessages } from "api/feedback";
import useFeedbackMessage from "hook/useFeedbackMessage";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Col, Form, FormGroup, FormLabel, InputGroup, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import ImageGallery from "react-image-gallery";
import { Can } from "components/can";
import { updateFeedbackStatus } from "api/feedback";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Message from "./Message";
import { getStatusColor } from "utils/utils";

export default function DetailsModal({ edit, setEdit }) {
  const messagesContainerRef = useRef();

  const { data, isLoading, refetch, isFetching } = useFeedbackMessage(edit?._id);

  const mutation = useMutation({
    mutationFn: (text) => doFeedbackMessages(edit?._id, text),
    onSuccess(data, variables, context) {
      refetch();
    },
  });
  const [msg, setMsg] = useState("");

  const mutation2 = useMutation({
    mutationFn: ({ id, status }) => updateFeedbackStatus(id, status),
  });

  const handleSendMessage = () => {
    mutation.mutate(msg);
    setMsg("");
  };

  useEffect(() => {
    messagesContainerRef?.current?.scrollTo({ top: messagesContainerRef.current.scrollHeight, behavior: "smooth" });
  }, [isFetching]);

  return (
    <>
      <Modal isOpen={Boolean(edit)} toggle={() => setEdit(null)} size="lg" centered>
        <ModalHeader>Feedback</ModalHeader>

        <ModalBody>
          <Row>
            <Col>
              <div>
                <h4 className="m-0">Basic Info</h4>
              </div>

              <div>
                <b>Ticket ID:</b> {edit?.ticketId}
              </div>

              <div>
                <b>Type:</b> <Badge bg={`${edit?.type == "app_error" ? "danger" : edit?.type == "other" ? "secondary" : edit?.type == "suggestion" ? "success" : "info"}`}>{edit?.type || "N/A"}</Badge>
              </div>

              <div>
                <b>Submitted At:</b> {moment(edit?.createdAt).format("lll")}
              </div>
            </Col>

            <Col>
              <div>
                <h4 className="m-0">User Info</h4>
              </div>
              <div>
                <b>Name:</b> {edit?.user?.name}
              </div>
              <div>
                <b>Phone Number:</b> {edit?.user?.phoneNumber}
              </div>
              <div>
                <b>Email:</b> {edit?.user?.phoneNumber}
              </div>
            </Col>

            <Col sm={12} className="mt-2">
              <div>
                <p>
                  <b>Description:</b> {edit?.description || "N/A"}
                </p>
              </div>
            </Col>
          </Row>

          {edit?.attachments?.length != 0 ? (
            <div>
              <b>Attachments: </b>
              <ImageGallery items={edit?.attachments.map((i) => ({ original: i, thumbnail: i }))} startIndex={0} showThumbnails={false} />
            </div>
          ) : null}

          <Can I="read" this="FeedbackReply">
            <b>Messages</b>

            <div style={{ height: 200, overflowX: "auto" }} ref={messagesContainerRef}>
              {isLoading && <Spinner animation="grow" variant="dark" />}

              {data?.data?.messages?.map((m) => (
                <Message message={m} />
              ))}

              {data?.data?.messages?.length == 0 ? "No messages!" : ""}
            </div>
          </Can>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex justify-content-between w-100">
            <Button variant="dark" onClick={() => setEdit(null)} size="sm" className="me-2">
              Close
            </Button>

            <Can I="create" this="FeedbackReply">
              <InputGroup>
                <Form.Control
                  className="border-primary"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  placeholder="Enter message..."
                  aria-label="Enter message..."
                  aria-describedby="basic-addon2"
                  as="textarea"
                />
                <Button variant="primary" id="button-addon2" size="sm" onClick={handleSendMessage}>
                  Send
                </Button>
              </InputGroup>
            </Can>

            <FormGroup className="ms-2">
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) => {
                  setEdit({ ...edit, status: e.target.value });
                  mutation2.mutate({ id: edit?._id, status: e.target.value });
                }}
                style={{
                  borderColor: getStatusColor(edit?.status),
                  boxShadow: `10px 0 0px ${getStatusColor(edit?.status)}`,
                }}
              >
                <option value="submitted" selected={edit?.status == "submitted"}>
                  Submitted
                </option>
                <option value="in-progress" selected={edit?.status == "in-progress"}>
                  In Progress
                </option>
                <option value="open" selected={edit?.status == "open"}>
                  Open
                </option>
                <option value="on-hold" selected={edit?.status == "on-hold"}>
                  On Hold
                </option>
                <option value="solved" selected={edit?.status == "solved"}>
                  Solved
                </option>
                <option value="closed" selected={edit?.status == "closed"}>
                  Closed
                </option>
              </Input>
            </FormGroup>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
