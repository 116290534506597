import api from ".";

export const getLast24HoursMessages = (skip = 0, limit = 20) => {
  return api.get("/sms/last-24-hours-messages", {
    params: {
      skip,
      limit,
    },
  });
};

export const getEmergencyAlerts = (skip = 0, limit = 20) => {
  return api.get("/sms/emergency-alerts", {
    params: {
      skip,
      limit,
    },
  });
};
