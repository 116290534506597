import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteAdmin } from "api/admin";
import { editAdmin } from "api/admin";
import { createAdmin } from "api/admin";
import { getAdmins } from "api/admin";
import CreateAdmin from "components/admin/CreateAdmin";
import EditAdmin from "components/admin/EditAdmin";
import { Can } from "components/can";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, FormGroup, FormLabel, Row, Table } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function AdminsPage() {
  const [showCreateAdminModal, setShowCreateAdminModal] = useState(false);
  const [admin, setAdmin] = useState();

  const { data, refetch } = useQuery({
    queryKey: ["addAdmins"],
    queryFn: getAdmins,
  });

  const m1 = useMutation({
    mutationFn: (data) => createAdmin(data),
    onSuccess(data, variables, context) {
      refetch();
      NotificationManager.success("Admin created successfully.");
    },
  });

  const m2 = useMutation({
    mutationFn: (data) => editAdmin(data.id, data),
    onSuccess(data, variables, context) {
      refetch();
      NotificationManager.success("Admin updated successfully.");
    },
  });

  const m3 = useMutation({
    mutationFn: (id) => deleteAdmin(id),
    onSuccess(data, variables, context) {
      refetch();
      NotificationManager.success("Admin deleted successfully.");
    },
  });

  return (
    <>
      {showCreateAdminModal && (
        <CreateAdmin
          onClose={() => setShowCreateAdminModal(false)}
          onSave={(data) => {
            m1.mutate(data);
            setShowCreateAdminModal(false);
          }}
        />
      )}

      {admin && (
        <EditAdmin
          admin={admin}
          onClose={() => setAdmin(null)}
          onSave={(data) => {
            m2.mutate({ ...data, id: admin._id });
            setAdmin(null);
          }}
        />
      )}

      <Container fluid>
        <Can I="create" this="Admin">
          <Row className="mb-4 ">
            <Col className="text-end">
              <Button variant="primary" size="sm" onClick={() => setShowCreateAdminModal(true)}>
                Add Admin
              </Button>
            </Col>
          </Row>
        </Can>
        <Can I="read" this="Admin">
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.admins?.map((admin, idx) => (
                    <tr>
                      <td>{idx + 1}</td>
                      <td>{admin?.name}</td>
                      <td>{admin?.email}</td>
                      <td>{admin?.role?.name}</td>
                      <td>
                        <Can I="update" this="Admin">
                          <Button variant="primary" size="sm" onClick={() => setAdmin(admin)}>
                            Edit
                          </Button>{" "}
                        </Can>
                        <Can I="delete" this="Admin">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              if (confirm("delete")) {
                                m3.mutate(admin._id);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Can>

        <Can not I="read" this="Admin">
          <Alert variant="danger">You do not have proper permission to access this information.</Alert>
        </Can>
      </Container>
    </>
  );
}

export default AdminsPage;
