import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getFeedbacks } from "../api/feedback";

const useFeedback = (type, status, search) => {
  const queryKey = ["getFeedback", type, status, search];

  return useQuery({
    queryKey,
    queryFn: () => getFeedbacks({ type, status, search }),
  });
};

export default useFeedback;
