import api from ".";

export const getAdmins = () => {
  return api.get("/admins");
};

export const createAdmin = (data) => {
  return api.post("/admins", data);
};

export const editAdmin = (id, data) => {
  return api.patch(`/admins/${id}`, data);
};

export const deleteAdmin = (id) => {
  return api.delete(`/admins/${id}`);
};

export const getRoles = () => {
  return api.get("/admins/roles");
};

export const createRole = (data) => {
  return api.post("/admins/roles", data);
};

export const updateRolePermission = (roleId, permission) => {
  return api.patch(`/admins/roles/update-permission/${roleId}`, { permission });
};
