import api from ".";

export const getLast10DaysUserRegistrationChartData = (type) => {
  return api.get("/chart/last-10-days-user-registration-chart-data", {
    params: {
      type,
    },
  });
};

export const getLast10DaysCheckedInChartData = (type) => {
  return api.get("/chart/last-10-days-checked-in-chart-data", {
    params: {
      type,
    },
  });
};

export const getFreeAndPremiumUsersChartData = (type) => {
  return api.get("/chart/free-and-premium-users-chart-data", {
    params: {
      type,
    },
  });
};

export const getAndroidAndIosChart = () => {
  return api.get("/chart/android-and-ios-users-chart-data");
};
