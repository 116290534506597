import { getLast10DaysUserRegistrationChartData } from "api/chart";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ArcElement } from "chart.js";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip);

const LastTenDayUserRegistrationChart = () => {
  const [data, setData] = useState(null);
  const [type, setType] = useState("daily");

  const loadData = async () => {
    try {
      setData(null);
      const { data } = await getLast10DaysUserRegistrationChartData(type);

      setData({
        labels: data.map((i) => i._id),
        datasets: [
          {
            data: data.map((i) => i.count),
            backgroundColor: [
              "#F06292",
              "#BA68C8",
              "#9575CD",
              "#7986CB",
              "#64B5F6",
              "#4FC3F7",
              "#4DD0E1",
              "#4DB6AC",
              "#81C784",
              "#AED581",
              "#DCE775",
              "#FFF176",
              "#FFD54F",
              "#FFB74D",
              "#FF8A65",
              "#A1887F",
              "#90A4AE",
              "#CFD8DC",
              "#B0BEC5",
              "#546E7A",
              "#455A64",
              "#37474F",
              "#263238",
              "#F06292",
            ],
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [type]);

  const options = {
    responsive: true,
    plugins: {},
  };

  return (
    <div className="card card-success">
      <div className="card-header">
        <h4 className="card-title text-center">User Registration Report</h4>

        <div className="d-flex justify-content-center mt-2 mb-2">
          <Form.Check checked={type == "daily"} onChange={() => setType("daily")} type="radio" label="Daily" id="daily_12341" />
          <Form.Check checked={type == "monthly"} onChange={() => setType("monthly")} type="radio" label="Monthly" id="monthly_324123" />
          <Form.Check checked={type == "yearly"} onChange={() => setType("yearly")} type="radio" label="Yearly" id="yearly_45345" />
        </div>
      </div>
      <div className="card-body">
        <div className="chart">{data != null && <Bar options={options} data={data} />}</div>
      </div>
    </div>
  );
};

export default LastTenDayUserRegistrationChart;
