import api from ".";

export const getCheckIns = (skip = 0, limit = 20, search, filter) => {
  return api.get("/check-in", {
    params: {
      search,
      skip,
      limit,
      filter,
    },
  });
};

export const getLast24HoursCheckIns = (skip = 0, limit = 20, search, filter) => {
  return api.get("/check-in/last-24-hours-check-ins", {
    params: {
      search,
      skip,
      limit,
      filter,
    },
  });
};

export const getLast24HoursMissedCheckInUsers = (skip = 0, limit = 20, search, filter) => {
  return api.get("/check-in/last-24-hours-missed-check-in-users", {
    params: {
      search,
      skip,
      limit,
      filter,
    },
  });
};
