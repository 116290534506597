import api from ".";

export const login = (idToken) => {
  return api.post("/auth/login-with-id-token", {
    id_token: idToken,
  });
};
export const loginWithEmailPassword = (email, password) => {
  return api.post("/auth/login-with-email-password", {
    email,
    password,
  });
};

export const getProfile = () => {
  return api.get("/auth/me");
};
