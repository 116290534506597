import { applyMiddleware } from "@reduxjs/toolkit";
import { useMutation } from "@tanstack/react-query";
import { deleteFeedback } from "api/feedback";
import { updateFeedbackStatus } from "api/feedback";
import { getFeedbacks } from "api/feedback";
import { deleteAccount } from "api/users";
import { getPremiumUsers } from "api/users";
import { updateUser } from "api/users";
import { getUsers } from "api/users";
import { Can } from "components/can";
import DetailsModal from "components/feedback/DetailsModal";
import SettingsModal from "components/feedback/SettingsModal";
import Search from "components/search/Search";
import ActivityModal from "components/users/ActivityModal";
import EditModal from "components/users/EditModal";
import UsersTable from "components/users/UsersTable";
import useFeedback from "hook/useFeedback";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Spinner, Alert, FormControl, FormSelect, FormGroup } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Input, Label } from "reactstrap";
import { getStatusColor } from "utils/utils";
import { isActiveAccount } from "utils/utils";
import { isCheckedIn } from "utils/utils";

function Feedback() {
  // const [premium, setPremium] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [edit, setEdit] = useState();

  const [status, setStatus] = useState("all");
  const [type, setType] = useState("all");

  const [search, setSearch] = useState("");

  const [showSettings, setSHowSettings] = useState(false);

  const { data, refetch } = useFeedback(type, status, search);

  const mutation = useMutation({
    mutationFn: ({ id, status }) => updateFeedbackStatus(id, status),
    onSuccess(data, variables, context) {
      refetch();
    },
  });

  const m2 = useMutation({
    mutationFn: (id) => deleteFeedback(id),
    onSuccess(data, variables, context) {
      refetch();
    },
  });

  const [feedback, setFeedback] = useState(null);

  function getDescriptionNthCharacter(description, n) {
    if (description.length > n) {
      return description.slice(0, n) + "...";
    } else {
      return description;
    }
  }

  return (
    <>
      <Container fluid>
        {feedback && (
          <DetailsModal
            edit={feedback}
            setEdit={(e) => {
              setFeedback(e);

              if (e == null) {
                loadMoreUsers(0, 5000, true);
              }
            }}
          />
        )}

        {showSettings && <SettingsModal hide={() => setSHowSettings(false)} />}

        <Can I="read" this="FeedbackEmails">
          <div className="text-end mb-2">
            <button className="mb-2" onClick={() => setSHowSettings(true)}>
              Email Settings
            </button>
          </div>
        </Can>

        <Can I="read" this="Feedback">
          <Row className="justify-content-end">
            <Col>
              <FormGroup className="mb-4 me-4 w-100">
                <Label>Search</Label>
                <Input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="p-2" placeholder="Search ..." />
              </FormGroup>
            </Col>
            <Col className="mb-2 d-flex" sm={4}>
              <FormGroup className="me-4 w-100">
                <Label>Type</Label>
                <Input type="select" name="select" id="exampleSelect" onChange={(e) => setType(e.target.value)}>
                  <option value="all" selected={type == "all"}>
                    All
                  </option>
                  <option value="app_error" selected={type == "app_error"}>
                    App Error
                  </option>
                  <option value="suggestion" selected={type == "suggestion"}>
                    Suggestion
                  </option>
                  <option value="check_in" selected={type == "check_in"}>
                    Check In
                  </option>
                  <option value="other" selected={type == "other"}>
                    Other
                  </option>
                </Input>
              </FormGroup>
              <FormGroup className="w-100">
                <Label>Status</Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  style={{
                    borderColor: getStatusColor(status),
                    boxShadow: `10px 0 0px ${getStatusColor(status)}`,
                  }}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="all" selected={status == "all"}>
                    All
                  </option>
                  <option value="submitted" selected={status == "submitted"}>
                    Submitted
                  </option>
                  <option value="in-progress" selected={status == "in-progress"}>
                    In Progress
                  </option>
                  <option value="open" selected={status == "open"}>
                    Open
                  </option>
                  <option value="on-hold" selected={status == "on-hold"}>
                    On Hold
                  </option>
                  <option value="solved" selected={status == "solved"}>
                    Solved
                  </option>
                  <option value="closed" selected={status == "closed"}>
                    Closed
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col sm={12}>
              <Table className="table-hover">
                <thead>
                  <tr>
                    <th className="border-0">ID</th>
                    <th className="border-0">Type</th>
                    <th className="border-0">User Name</th>
                    <th className="border-0">Phone Number</th>
                    <th className="border-0">Content</th>
                    <th className="border-0">Ticket Id</th>
                    <th className="border-0">Submitted At</th>
                    <th className="border-0">Status</th>
                    <th className="border-0">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.feedbacks.map((u, idx) => (
                    <tr key={idx}>
                      {/* {JSON.stringify(u)} */}
                      <td>{idx + 1}</td>
                      <td>
                        <Badge bg={`${u?.type == "app_error" ? "danger" : u?.type == "other" ? "secondary" : u?.type == "suggestion" ? "success" : "info"}`}>{u?.type || "N/A"}</Badge>
                      </td>
                      <td>{u?.user?.name}</td>
                      <td>{u?.user?.phoneNumber}</td>

                      <td>{getDescriptionNthCharacter(u.description, 50) || "N/A"}</td>
                      <td style={{ minWidth: 80 }}>{u?.ticketId}</td>
                      <td style={{ minWidth: 150 }}>{moment(u.createdAt).format("ll")}</td>

                      <td style={{ minWidth: 140 }}>
                        <FormGroup>
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            style={{
                              borderColor: getStatusColor(u?.status),
                              boxShadow: `10px 0 0px ${getStatusColor(u?.status)}`,
                            }}
                            onChange={(e) => mutation.mutate({ id: u._id, status: e.target.value })}
                          >
                            <option value="submitted" selected={u?.status == "submitted"}>
                              Submitted
                            </option>
                            <option value="in-progress" selected={u?.status == "in-progress"}>
                              In Progress
                            </option>
                            <option value="open" selected={u?.status == "open"}>
                              Open
                            </option>
                            <option value="on-hold" selected={u?.status == "on-hold"}>
                              On Hold
                            </option>
                            <option value="solved" selected={u?.status == "solved"}>
                              Solved
                            </option>
                            <option value="closed" selected={u?.status == "closed"}>
                              Closed
                            </option>
                          </Input>
                        </FormGroup>
                      </td>

                      <td style={{ minWidth: 140 }}>
                        <Button size="sm" className="btn-round me-2" onClick={() => setFeedback(u)}>
                          Details
                        </Button>

                        <Can I="delete" this="Feedback">
                          <Button
                            size="sm"
                            variant="danger"
                            className="btn-round"
                            onClick={() => {
                              if (confirm("Confirm delete")) {
                                m2.mutate(u?._id);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </Can>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Can>

        <Can not I="read" this="Feedback">
          <Alert variant="danger">You do not have proper permission to access this information.</Alert>
        </Can>
      </Container>
    </>
  );
}

export default Feedback;
