import { updateUser } from "api/users";
import { deleteAccount } from "api/users";
import { getFreeUsers } from "api/users";
import Search from "components/search/Search";
import ActivityModal from "components/users/ActivityModal";
import EditModal from "components/users/EditModal";
import UsersTable from "components/users/UsersTable";
import React, { useEffect } from "react";
import { useState } from "react";

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { isActiveAccount } from "utils/utils";
import { isCheckedIn } from "utils/utils";

function TableList() {
  // const [premium, setPremium] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [edit, setEdit] = useState();
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState("name");
  const [sorting, setSort] = useState("asc");
  const [loading, setLoading] = useState(false);

  const handleSort = async () => {
    try {
      setHasMore(true);

      setUsers([]);

      const { data } = await getFreeUsers(0, 20, keyword, filter, sorting);

      setUsers(data.users);
      setHasMore(data.users.length === 20);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSort();
  }, [sorting]);

  const handleSearch = async () => {
    try {
      setHasMore(true);
      setUsers([]);

      const { data } = await getFreeUsers(0, 20, keyword, filter);

      setUsers(data.users);
      setHasMore(data.users.length === 20);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreUsers = async (skip, limit = 20) => {
    try {
      setLoading(true);
      setHasMore(true);

      const { data } = await getFreeUsers(skip || users.length, limit, keyword, filter);
      setLoading(false);

      console.log(data.users.length);
      setUsers((prev) => [...prev, ...data.users]);
      setHasMore(data.users.length === 20);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    loadMoreUsers();
  }, []);
  const handleDelete = async (userID) => {
    try {
      if (confirm("The account will be deleted permanently. You can't undo this action.")) {
        const { data } = await deleteAccount(userID);

        setUsers((prev) => prev.filter((u) => u._id !== data.deletedUserId));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (user) => {
    try {
      const { data } = await updateUser(user);

      setUsers((prev) =>
        prev.map((u) => {
          if (u._id === data?.user?._id) u = data.user;
          return u;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [activity, showActivity] = useState(null);

  return (
    <>
      <Container fluid>
        <EditModal edit={edit} setEdit={setEdit} handleUpdate={handleUpdate} />
        <ActivityModal user={activity} onClose={() => showActivity(null)} />

        <Row>
          <Search keyword={keyword} filter={filter} setFilter={setFilter} setKeyword={setKeyword} handleSearch={handleSearch} />

          <UsersTable
            loading={loading}
            sorting={sorting}
            setSort={setSort}
            users={users}
            hasMore={hasMore}
            setEdit={setEdit}
            loadMore={loadMoreUsers}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            showActivity={showActivity}
          />
        </Row>
      </Container>
    </>
  );
}

export default TableList;
