import api from ".";

export const sendEmailToAllUser = (title, content, users, target) => {
  return api.post("/email/send-to-all", {
    title,
    content,
    users,
    target,
  });
};

export const getEmailTemplates = () => {
  return api.get("/email/templates");
};

export const createEmailTemplate = (name, subject, content) => {
  return api.post("/email/templates", {
    name,
    subject,
    content,
  });
};
