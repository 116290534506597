import { getQuotes } from "api/quote";
import { deleteQuote } from "api/quote";
import { addQuote } from "api/quote";
import { updateQuote } from "api/quote";
import { Can } from "components/can";
import AddQuoteModal from "components/quote/AddQuoteModal";
import EditQuoteModal from "components/quote/EditQuoteModal";
import React, { useEffect, useState } from "react";

import { Button, Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function Configuration() {
  const [quotes, setQuotes] = useState([]);

  const loadQuotes = async () => {
    try {
      const { data } = await getQuotes();

      setQuotes(data.quotes);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  useEffect(() => {
    loadQuotes();
  }, []);

  const handleUpdate = async (quote) => {
    try {
      if (quote?.author === "" || quote?.content === "") {
        NotificationManager.error("Author and Content is required.");

        return;
      }

      await updateQuote(quote);

      setQuotes((prev) => prev.map((i) => (i._id == quote._id ? quote : i)));

      NotificationManager.success("Quote successfully added.");
    } catch (error) {
      console.log(error);
      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  const handleSave = async (quote) => {
    try {
      if (quote?.author === "" || quote?.content === "") {
        NotificationManager.error("Author and Content is required.");

        return;
      }

      const { data } = await addQuote(quote);

      setQuotes((prev) => [...prev, data.quote]);

      NotificationManager.success("Quote successfully updated.");
    } catch (error) {
      console.log(error);
      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Are you sure?")) {
        await deleteQuote(id);

        setQuotes((prev) => prev.filter((i) => i._id !== id));

        NotificationManager.success("Quote successfully deleted.");
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  const [edit, setEdit] = useState(null);

  const [quote, setQuote] = useState(null);

  return (
    <>
      <EditQuoteModal edit={edit} setEdit={setEdit} handleUpdate={handleUpdate} />

      <AddQuoteModal quote={quote} setQuote={setQuote} handleSave={handleSave} />

      <Container fluid>
        {/* Loader */}
        {quotes.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300,
            }}
          >
            <Spinner animation="grow" variant="dark" />
          </div>
        )}
        <Can I="read" this="Quote">
          <Row>
            {quotes.map((t, idx) => (
              <Col
                key={idx}
                xs={12}
                className="position-relative mb-4"
                style={{
                  borderLeft: t?.active ? "5px solid green" : "none",
                }}
              >
                <h3>{String(t?.author).replaceAll("_", " ")}</h3>

                <p>{t?.content}</p>

                <div
                  className="position-absolute"
                  style={{
                    top: 8,
                    right: 8,
                  }}
                >
                  <Can I="delete" this="Quote">
                    <button onClick={() => handleDelete(t._id)}>Delete</button>
                  </Can>

                  <Can I="update" this="Quote">
                    <button onClick={() => setEdit(t)}>Edit</button>
                  </Can>
                </div>
              </Col>
            ))}
            <Can I="create" this="Quote">
              <Col className="text-center mt-4" onClick={() => setQuote({ author: "", content: "", active: false })}>
                <Button>Add Quote</Button>
              </Col>
            </Can>
          </Row>
        </Can>

        <Can not I="read" this="Quote">
          <Alert variant="danger">You do not have proper permission to access this information.</Alert>
        </Can>
      </Container>
    </>
  );
}

export default Configuration;
