import { useMutation, useQuery } from "@tanstack/react-query";
import { createRole } from "api/admin";
import { updateRolePermission } from "api/admin";
import { getRoles } from "api/admin";
import Role from "components/admin/Role";
import { Can } from "components/can";
import CreateRole from "components/role/CreateRole";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, FormGroup, FormLabel, ProgressBar, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const generateConfig = (value) => ({
  FAQ: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  Stats: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  Configuration: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  Quote: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  User: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  PushNotification: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  Email: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  Feedback: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  FeedbackEmails: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  FeedbackReply: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  Admin: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
  Role: {
    create: value,
    read: value,
    update: value,
    delete: value,
  },
});

function RolesPage() {
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);

  const [access, setAccess] = useState(generateConfig(false));
  const [selectedRole, setSelectedRole] = useState();

  const { refetch, isLoading, data } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  const mutation = useMutation({
    mutationFn: (roleId) => updateRolePermission(roleId, access),
    onSuccess(data, variables, context) {
      refetch();
      NotificationManager.success("Permission successfully saved.");
    },
  });

  const m2 = useMutation({
    mutationFn: (data) => createRole(data),
    onSuccess(data, variables, context) {
      refetch();
      NotificationManager.success("New role created.");
    },
  });

  useEffect(() => {
    if (data?.data?.roles[0].permission) {
      setAccess(data?.data?.roles[0].permission);
      setSelectedRole(data?.data?.roles[0]);
    }
  }, [data?.data?.roles]);

  if (isLoading)
    return (
      <div className="d-flex w-100 justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="grow" variant="dark" />
      </div>
    );

  return (
    <>
      {showCreateRoleModal && (
        <CreateRole
          onClose={() => setShowCreateRoleModal(false)}
          onSave={(name) => {
            setShowCreateRoleModal(false);
            m2.mutate({ name });
          }}
        />
      )}

      <Container fluid>
        <Can I="read" this="Role">
          <Row className="mb-4 d-flex align-items-center">
            <Col>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  const role = data?.data?.roles?.find((r) => r._id == e.target.value);
                  setAccess(role?.permission);
                  setSelectedRole(role);
                }}
              >
                {data?.data?.roles?.map((role, idx) => (
                  <option key={idx} value={role._id} selected={selectedRole?._id == role?._id}>
                    {role?.name}
                  </option>
                ))}
              </Form.Select>
            </Col>

            <Can I="update" this="Role">
              <Col>
                <Button size="sm" onClick={() => mutation.mutate(selectedRole._id)}>
                  {mutation?.isPending ? (
                    <Spinner size="sm" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Col>
            </Can>

            <Col className="text-end">
              <input
                type="checkbox"
                id="full-access"
                checked={Object.values(access).every((obj) => Object.values(obj).every((value) => value === true))}
                onChange={(e) => setAccess(generateConfig(e.target.checked))}
              />
              <label className="mx-2" htmlFor="full-access">
                Provide Full Access
              </label>
            </Col>

            <Can I="create" this="Role">
              <Col sm={12} className="mt-2">
                Role not found?{" "}
                <span className="text-primary pointer" onClick={() => setShowCreateRoleModal(true)}>
                  Add New Role
                </span>
              </Col>
            </Can>
          </Row>
          <Row>
            <Role
              title="Dashboard Stats"
              status={access.Stats}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  Stats: val,
                }));
              }}
            />

            <Role
              title="Configuration"
              status={access.Configuration}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  Configuration: val,
                }));
              }}
            />

            <Role
              title="Quote"
              status={access.Quote}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  Quote: val,
                }));
              }}
            />

            <Role
              title="User"
              status={access.User}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  User: val,
                }));
              }}
            />

            <Role
              title="Push Notification"
              status={access.PushNotification}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  PushNotification: val,
                }));
              }}
            />

            <Role
              title="Email"
              status={access.Email}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  Email: val,
                }));
              }}
            />

            <Role
              title="Feedback Emails"
              status={access.FeedbackEmails}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  FeedbackEmail: val,
                }));
              }}
            />

            <Role
              title="Feedback"
              status={access.Feedback}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  Feedback: val,
                }));
              }}
            />

            <Role
              title="Feedback Reply"
              status={access.FeedbackReply}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  FeedbackReply: val,
                }));
              }}
            />

            <Role
              title="Admin"
              status={access.Admin}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  Admin: val,
                }));
              }}
            />

            <Role
              title="Role"
              status={access.Role}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  Role: val,
                }));
              }}
            />

            <Role
              title="FAQ"
              status={access.FAQ}
              onChange={(val) => {
                setAccess((prev) => ({
                  ...prev,
                  FAQ: val,
                }));
              }}
            />
          </Row>
        </Can>

        <Can not I="read" this="Role">
          <Alert variant="danger">You do not have proper permission to access this information.</Alert>
        </Can>
      </Container>
    </>
  );
}

export default RolesPage;
