import { getLast24HoursMessages } from "api/sms";
import React, { useEffect } from "react";
import { useState } from "react";

import moment from "moment";

import { Table, Container, Row, Col, Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

function TableList() {
  const [messages, setMessages] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMessages = async (skip, limit = 20) => {
    try {
      setHasMore(true);

      const { data } = await getLast24HoursMessages(skip || messages.length, limit);

      console.log(data.messages.length);
      setMessages((prev) => [...prev, ...data.messages]);
      setHasMore(data.messages.length === 20);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadMessages();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <InfiniteScroll
            dataLength={messages.length} //This is important field to render the next data
            next={loadMessages}
            hasMore={hasMore}
            loader={
              <div className="text-center py-4">
                <Spinner animation="grow" />
              </div>
            }
            scrollableTarget="main-panel"
          >
            <Col sm={12}>
              <Table className="table-hover">
                <thead>
                  <tr>
                    <th className="border-0">From</th>
                    <th className="border-0">To</th>
                    <th className="border-0">Content</th>
                    <th className="border-0">Send At</th>
                    <th className="border-0">Is Emergency Alert</th>
                  </tr>
                </thead>
                <tbody>
                  {messages.map((u, idx) => (
                    <tr key={idx}>
                      <td>{u?.from}</td>
                      <td>{u?.to}</td>
                      <td style={{ maxWidth: 200 }}>{u?.content ?? "-"}</td>
                      <td>{moment(u?.createdAt).format("DD MMM YYYY hh:mm a")}</td>
                      <td>{u?.isEmergency ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </InfiniteScroll>
        </Row>
      </Container>
    </>
  );
}

export default TableList;
