import React from "react";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { Input, Modal } from "reactstrap";

export default function EditQuoteModal({ edit, setEdit, handleUpdate }) {
  return (
    <Modal isOpen={edit} toggle={() => setEdit(null)}>
      <ModalHeader>Edit</ModalHeader>

      <ModalBody>
        <FormGroup className="mb-2">
          <FormLabel for="exampleEmail">Author</FormLabel>
          <Input
            type="text"
            value={edit?.author}
            name="email"
            id="exampleEmail"
            placeholder="Enter author name..."
            onChange={(e) =>
              setEdit({
                ...edit,
                author: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="mb-2">
          <FormLabel for="content">Content</FormLabel>
          <textarea
            style={{
              width: "100%",
            }}
            className="p-2 border"
            rows={2}
            value={edit?.content}
            onChange={(e) =>
              setEdit({
                ...edit,
                content: e.target.value,
              })
            }
          ></textarea>
        </FormGroup>

        <FormGroup>
          <FormLabel for="p">Status</FormLabel>

          <Form.Check
            checked={edit?.active}
            onChange={() => {
              setEdit((prev) => ({
                ...prev,
                active: !prev.active,
              }));
            }}
            type={"radio"}
            label={`Active`}
            id="active"
            name="p"
          />
          <Form.Check
            checked={!edit?.active}
            onChange={() => {
              setEdit((prev) => ({
                ...prev,
                active: !prev.active,
              }));
            }}
            type={"radio"}
            label={`Inactive`}
            id="inactive"
            name="p"
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={() => setEdit(null)}>
          Discard
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setEdit(null);
            handleUpdate(edit);
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
}
