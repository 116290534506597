import { Can } from "components/can";
import moment from "moment";
import React from "react";
import { Button, Col, Spinner, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { isCheckedIn } from "utils/utils";
import { isActiveAccount } from "utils/utils";

export default function UsersTable({
  loading = false,
  sorting = "asc",
  setSort,
  users,
  loadMore,
  hasMore,
  setEdit,
  handleDelete,
  showActivity,
}) {
  return (
    <>
      <Col sm={12}>
        <Table className="table-hover">
          <thead>
            <tr>
              <th className="border-0">ID</th>
              <th className="border-0">Joined At</th>
              <th className="border-0">Username</th>
              <th className="border-0">Name</th>
              <th className="border-0">Email</th>
              <th className="border-0">Phone Number</th>
              <th className="border-0">Is Account Active</th>
              <th className="border-0">
                <span>Is Checked In </span>
                <br />
                <i
                  className={`nc-icon nc-stre-down pointer me-2 ${
                    sorting === "asc" ? "text-black" : ""
                  }`}
                  onClick={() => setSort("asc")}
                ></i>
                <i
                  className={`nc-icon nc-stre-up pointer ${
                    sorting === "desc" ? "text-black" : ""
                  }`}
                  onClick={() => setSort("desc")}
                ></i>
              </th>
              <th className="border-0">Account Type</th>
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((u, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{moment(u.createdAt).format("ll")}</td>
                <td>{u.username}</td>
                <td>{u.name}</td>
                <td>{u.email}</td>
                <td>{u.phoneNumber}</td>

                <td>{isActiveAccount(u?.lastCheckInTime)}</td>

                <td>{isCheckedIn(u.lastCheckInTime)}</td>

                <td>{u?.isPurchased ? "Premium" : "Free"}</td>

                <td>
                  <Can I="update" this="User">
                    <Button
                      size="sm"
                      className="btn-round me-2"
                      onClick={() => setEdit(u)}
                    >
                      Edit
                    </Button>
                  </Can>
                  <Can I="delete" this="User">
                    <Button
                      size="sm"
                      className="btn-round me-2"
                      onClick={() => handleDelete(u._id)}
                      color="danger"
                    >
                      Delete
                    </Button>
                  </Can>
                  <Button
                    size="sm"
                    className="btn-round"
                    onClick={() => showActivity(u._id)}
                    color="danger"
                  >
                    Activity
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <Col sm={12} className="text-center">
        {hasMore && (
          <button
            onClick={() => {
              if (loading) return;
              loadMore(users.length);
            }}
          >
            {loading ? "Loading..." : "Load More"}
          </button>
        )}
      </Col>
    </>
  );
}
