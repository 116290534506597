import { getLast24HoursCheckIns } from "api/check-in";
import React, { useEffect } from "react";
import { useState } from "react";

import moment from "moment";

import { Table, Container, Row, Col, Spinner, Card } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { isCheckedIn } from "utils/utils";
import Search from "components/search/Search";

function TableList() {
  const [checkIns, setCheckIns] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState("name");

  const handleSearch = async () => {
    try {
      setHasMore(true);

      setCheckIns([]);
      const { data } = await getLast24HoursCheckIns(0, 20, keyword, filter);
      setCheckIns(data.checkIns);
      setHasMore(data.checkIns.length === 20);
    } catch (error) {
      console.log(error);
    }
  };

  const loadCheckIns = async (skip, limit = 20) => {
    try {
      setHasMore(true);

      const { data } = await getLast24HoursCheckIns(skip || checkIns.length, limit, keyword, filter);

      setCheckIns((prev) => [...prev, ...data.checkIns]);
      setHasMore(data.checkIns.length === 20);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadCheckIns();
  }, []);

  return (
    <>
      <Container fluid>
        <Search keyword={keyword} filter={filter} setFilter={setFilter} setKeyword={setKeyword} handleSearch={handleSearch} />

        <Row>
          <InfiniteScroll
            dataLength={checkIns.length} //This is important field to render the next data
            next={loadCheckIns}
            hasMore={hasMore}
            loader={
              <div className="text-center py-4">
                <Spinner animation="grow" />
              </div>
            }
            scrollableTarget="main-panel"
          >
            <Col sm={12}>
              <Table className="table-hover">
                <thead>
                  <tr>
                    <th className="border-0">Status</th>
                    <th className="border-0">Text</th>
                    <th className="border-0">Checked In At</th>
                    <th className="border-0">User Name</th>
                    <th className="border-0">Phone Number</th>
                    <th className="border-0">Is Checked In</th>
                    <th className="border-0">Account Type</th>
                  </tr>
                </thead>
                <tbody>
                  {checkIns.map((u, idx) => (
                    <tr key={idx}>
                      <td>
                        <img width={40} height={40} src={`/emoji/${u?.status}.png`} />
                      </td>
                      <td style={{ maxWidth: 200 }}>{u?.text ?? "-"}</td>
                      <td>{moment(u?.createdAt).format("DD MMM YYYY hh:mm a")}</td>
                      <td>{u?.user?.name}</td>
                      <td>{u?.user?.phoneNumber}</td>
                      <td>{isCheckedIn(u?.user?.lastCheckInTime)}</td>
                      <td>{u?.user?.isPurchased ? "Premium" : "Free"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </InfiniteScroll>
        </Row>
      </Container>
    </>
  );
}

export default TableList;
