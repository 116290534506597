import { updateTemplate } from "api/templates";
import { getTemplates } from "api/templates";
import { Can } from "components/can";
import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function Configuration() {
  const [templates, setTemplates] = useState([]);
  const [saving, setSaving] = useState();

  const loadTemplates = async () => {
    try {
      const { data } = await getTemplates();

      setTemplates(data.templates);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  const handleUpdate = async (name) => {
    try {
      setSaving(name);
      await updateTemplate(templates.find((t) => t?.name === name));

      NotificationManager.success("Configuration successfully updated.");
      setSaving(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container fluid>
        {templates.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 300,
            }}
          >
            <Spinner animation="grow" variant="dark" />
          </div>
        )}

        <Can I="read" this="Configuration">
          <Row>
            {templates.map((t, idx) => (
              <Col key={idx} xs={12}>
                <h3>{String(t?.name).replaceAll("_", " ")}</h3>

                {t?.title !== undefined && (
                  <input
                    className="mb-3 p-2"
                    style={{
                      width: "100%",
                    }}
                    placeholder="Title"
                    value={t?.title}
                    onChange={(e) =>
                      setTemplates((prev) =>
                        prev.map((i) => {
                          if (i.name === t.name) {
                            i.title = e.target.value;
                          }
                          return i;
                        })
                      )
                    }
                  />
                )}

                <br />
                {t?.content !== undefined && (
                  <textarea
                    style={{
                      width: "100%",
                    }}
                    className=" p-2"
                    rows={10}
                    value={t?.content}
                    onChange={(e) =>
                      setTemplates((prev) =>
                        prev.map((i) => {
                          if (i.name === t.name) {
                            i.content = e.target.value;
                          }
                          return i;
                        })
                      )
                    }
                  ></textarea>
                )}

                <Can I="update" this="Configuration">
                  <div className="text-center">
                    <Button className="mt-3" onClick={() => handleUpdate(t?.name)}>
                      {saving === t?.name && <Spinner animation="grow" size="sm" />} Save
                    </Button>
                  </div>
                </Can>
              </Col>
            ))}
          </Row>
        </Can>

        <Can not I="read" this="Configuration">
          <Alert variant="danger">You do not have proper permission to access this information.</Alert>
        </Can>
      </Container>
    </>
  );
}

export default Configuration;
