import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ArcElement } from "chart.js";
import { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { getFreeAndPremiumUsersChartData } from "api/chart";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip);

const FreeAndPremiumUsersChart = () => {
  const [data, setData] = useState();

  const loadData = async () => {
    try {
      const {
        data: { freeUsers, premiumUsers },
      } = await getFreeAndPremiumUsersChartData();
      setData({
        labels: ["Free Users", "Premium Users"],
        datasets: [
          {
            label: "# of Users",
            data: [freeUsers, premiumUsers],
            backgroundColor: ["rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
            borderColor: ["rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="card card-success">
      <div className="card-header">
        <h4 className="card-title text-center">Free vs Premium User</h4>
      </div>
      <div className="card-body">
        <div className="chart">{data != null && <Pie data={data} />}</div>
      </div>
    </div>
  );
};

export default FreeAndPremiumUsersChart;
