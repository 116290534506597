import { sendPushNotificationToAllUser } from "api/notification";
import { Can } from "components/can";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function SendNotification({ users, target }) {
  console.log(users);

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleSend = async () => {
    try {
      if (isLoading) return;

      if (title != "" && content != "") {
        setLoading(true);
        await sendPushNotificationToAllUser(title, content, users, target);
        setTitle("");
        setContent("");
        setLoading(false);

        NotificationManager.success("Notification successfully sent.");
      } else {
        NotificationManager.error("Title and content is required.");
      }
    } catch (error) {
      setLoading(false);

      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Title</Form.Label>
        <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} type="email" placeholder="Enter notification title" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Content</Form.Label>
        <Form.Control value={content} onChange={(e) => setContent(e.target.value)} as="textarea" rows={3} style={{ height: "unset" }} />
      </Form.Group>

      <Can I="create" this="PushNotification">
        <div className="text-center">
          <Button onClick={handleSend}>{isLoading ? "Sending..." : "Send"}</Button>
        </div>
      </Can>
    </>
  );
}

export default SendNotification;
