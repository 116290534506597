import api from ".";

export const sendPushNotificationToAllUser = (title, content, users, target) => {
  return api.post("/notification/send-to-all", {
    title,
    content,
    users,
    target,
  });
};
