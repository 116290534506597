import React from "react";
import { Card, Col } from "react-bootstrap";
import { CardBody, CardHeader } from "reactstrap";

export default function Role({ title, status, onChange }) {
  const idForAll = "id_" + Math.random() * 9999999;

  return (
    <Col sm={6} md={4} lg={3}>
      <Card>
        <CardHeader>
          <span className="font-weight-bold">{title}</span>
        </CardHeader>

        <CardBody>
          <div className="border-bottom mb-2">
            <input
              type="checkbox"
              id={idForAll}
              checked={Object.values(status).every((value) => value === true)}
              onChange={(e) => {
                onChange({
                  create: e.target.checked,
                  read: e.target.checked,
                  update: e.target.checked,
                  delete: e.target.checked,
                });
              }}
            />
            <label className="mx-2" htmlFor={idForAll}>
              All
            </label>
          </div>

          {Object.keys(status).map((key) => {
            const id = "id_" + Math.random() * 9999999;

            return (
              <div key={key}>
                <input
                  type="checkbox"
                  id={id}
                  checked={status[key]}
                  onChange={(e) => {
                    onChange({
                      ...status,
                      [key]: e.target.checked,
                    });
                  }}
                />
                <label className="mx-2" htmlFor={id}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
              </div>
            );
          })}

          {/* <div>
            <input
              type="checkbox"
              id={ids[1]}
              checked={status?.create}
              onChange={(e) =>
                onChange({
                  ...status,
                  create: e.target.checked,
                })
              }
            />
            <label className="mx-2" htmlFor={ids[1]}>
              Create
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id={ids[2]}
              checked={status?.read}
              onChange={(e) =>
                onChange({
                  ...status,
                  read: e.target.checked,
                })
              }
            />
            <label className="mx-2" htmlFor={ids[2]}>
              Read
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id={ids[3]}
              checked={status?.update}
              onChange={(e) =>
                onChange({
                  ...status,
                  update: e.target.checked,
                })
              }
            />
            <label className="mx-2" htmlFor={ids[3]}>
              Update
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id={ids[4]}
              checked={status?.delete}
              onChange={(e) =>
                onChange({
                  ...status,
                  delete: e.target.checked,
                })
              }
            />
            <label className="mx-2" htmlFor={ids[4]}>
              Delete
            </label>
          </div> */}
        </CardBody>
      </Card>
    </Col>
  );
}
