import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { sendEmailToAllUser } from "api/email";
import { Can } from "components/can";
import CreateEmailTemplateModal from "./CreateEmailTemplateModal";
import { createEmailTemplate } from "api/email";
import { getEmailTemplates } from "api/email";
import { useMutation, useQuery } from "@tanstack/react-query";

export const placeholder = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </head>
  <body>
    <h1>Hello World!</h1>
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Totam temporibus, excepturi quas consequatur, hic corrupti neque quo maxime numquam, beatae id 
      uscipit. Totam illo repudiandae
      assumenda voluptatum error, laborum dolore!
    </p>
  </body>
</html>`;

function SendEmail({ users, target }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isLoading, setLoading] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["email_templates"],
    queryFn: getEmailTemplates,
  });

  useEffect(() => {
    if (data?.data?.templates?.length) {
      setTitle(data?.data?.templates[0]?.subject);
      setContent(data?.data?.templates[0]?.content);
    }
  }, [data?.data?.templates]);

  const mutation = useMutation({
    mutationFn: (data) => createEmailTemplate(data?.name, data?.subject, data?.content),
    onSuccess: () => {
      refetch();
    },
  });

  const handleSend = async () => {
    try {
      if (isLoading) return;

      if (title != "" && content != "") {
        setLoading(true);
        await sendEmailToAllUser(title, content, users, target);
        setLoading(false);

        NotificationManager.success("Notification successfully sent.");
      } else {
        NotificationManager.error("Title and content is required.");
      }
    } catch (error) {
      setLoading(false);

      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  const [createTemplate, setCreateTemplate] = useState(false);

  const handleCreateCustomTemplate = (name, subject, content) => {
    setCreateTemplate(false);

    mutation.mutate({ name, subject, content });
  };

  return (
    <>
      <CreateEmailTemplateModal show={createTemplate} onClose={() => setCreateTemplate(false)} onCreate={handleCreateCustomTemplate} />
      <Form.Group className="mb-2">
        <Form.Label for="template">Template</Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => {
            const temp = data?.data?.templates?.find(({ _id }) => _id == e.target.value);
            console.log(temp);
            setTitle(temp?.subject);
            setContent(temp?.content);
          }}
        >
          {data?.data?.templates?.map((t, idx) => (
            <option value={t._id}>{t?.name}</option>
          ))}
        </Form.Select>

        <button className="nav-link text-primary mt-1" onClick={() => setCreateTemplate(true)}>
          Create Template
        </button>
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Subject</Form.Label>
        <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} type="email" placeholder="Enter email subject" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Content/html</Form.Label>

        <CodeEditor
          value={content}
          language="html"
          placeholder={placeholder}
          onChange={(e) => setContent(e.target.value)}
          padding={15}
          style={{
            overflowY: "auto",
            height: 350,
            fontSize: 12,
            backgroundColor: "#000",
            fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
          }}
        />
      </Form.Group>

      <Can I="create" this="Email">
        <div className="text-center">
          <Button onClick={handleSend}>{isLoading ? "Sending..." : "Send"}</Button>
        </div>
      </Can>
    </>
  );
}

export default SendEmail;
