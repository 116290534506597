import { defineAbility } from "@casl/ability";

const defineAbilityFor = (permissions) => {
  return defineAbility((can, cannot) => {
    console.log(permissions);

    for (const entity in permissions) {
      for (const action in permissions[entity]) {
        if (permissions[entity][action]) {
          can(action, entity);
        } else {
          cannot(action, entity);
        }
      }
    }
  });
};

export default defineAbilityFor;
