import React, { Component, useEffect } from "react";
import { useLocation, Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import routes from "routes.js";

import sidebarImage from "assets/img/sidebar-1.jpg";
import { AbilityContext } from "components/can";
import ability from "../ability";
import defineAbilityFor from "../ability";
import { useQueries, useQuery } from "@tanstack/react-query";
import { getProfile } from "api/auth";
import { Alert, Spinner } from "react-bootstrap";

function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);

  const location = useLocation();
  const mainPanel = React.useRef(null);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };

  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainPanel.current.scrollTop = 0;
  //   if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
  //     document.documentElement.classList.toggle("nav-open");
  //     var element = document.getElementById("bodyClick");
  //     element.parentNode.removeChild(element);
  //   }
  // }, [location]);

  if (isLoading) {
    return (
      <div className="d-flex w-100 justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Spinner animation="grow" variant="dark" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="d-flex w-100 justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Alert variant="danger">Something want wrong!</Alert>
      </div>
    );
  } else if (data?.data?.admin) {
    return (
      <>
        <AbilityContext.Provider value={defineAbilityFor(data?.data?.admin?.role?.permission)}>
          <div className="wrapper">
            <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
            <div className="main-panel" id="main-panel" ref={mainPanel}>
              <AdminNavbar />
              <div className="content">
                <Switch>{getRoutes(routes)}</Switch>
              </div>
            </div>
          </div>
        </AbilityContext.Provider>
      </>
    );
  }
}

export default Admin;
