import api from ".";

export const getFAQs = (skip = 0, limit = 20) => {
  return api.get("/faq", {
    params: {
      skip,
      limit,
    },
  });
};

export const createFAQ = (faq) => {
  return api.post("/faq", faq);
};

export const updateFaq = (faq) => {
  return api.put("/faq/update-faq", faq);
};

export const deleteFaq = (id) => {
  return api.delete(`/faq/delete-faq/${id}`);
};
