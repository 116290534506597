import { useQuery } from "@tanstack/react-query";
import { getUsersSelectOptions } from "api/dashboard";
import { Can } from "components/can";
import SendEmail from "components/notification/SendEmail";
import SendNotification from "components/notification/SendNotification";
import React, { useState } from "react";
import { Alert, Form, Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

function Notification() {
  const [target, setTarget] = useState("all");

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const { data } = useQuery({ queryKey: ["usersSelectOptions"], queryFn: () => getUsersSelectOptions() });

  const [users, setUsers] = useState([]);

  return (
    <>
      <div className="mb-4">
        <h3>Action applied for</h3>
        <Form.Select aria-label="Default select example" onChange={(e) => setTarget(e.target.value)}>
          <option value="all">All User</option>
          <option value="selected">Selected users</option>
        </Form.Select>
      </div>

      {target == "selected" && (
        <div className="mb-4">{data?.data?.options.length && <Select onChange={(e) => setUsers(e.map((i) => i.value))} components={animatedComponents} isMulti options={data?.data?.options} />}</div>
      )}

      <Tabs defaultActiveKey="pn" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="pn" title="Push Notification">
          <Can I="read" this="PushNotification">
            <SendNotification users={users} target={target} />
          </Can>
          <Can not I="read" this="PushNotification">
            <Alert variant="danger">You do not have proper permission to access this information.</Alert>
          </Can>
        </Tab>
        <Tab eventKey="email" title="Email">
          <Can I="read" this="Email">
            <SendEmail users={users} target={target} />
          </Can>
          <Can not I="read" this="Email">
            <Alert variant="danger">You do not have proper permission to access this information.</Alert>
          </Can>
        </Tab>
      </Tabs>
    </>
  );
}

export default Notification;
