import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("JCI_ADMIN_AUTH_USER_DATA")),
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      localStorage.setItem("JCI_ADMIN_AUTH_USER_DATA", JSON.stringify(payload));
      state.user = payload;
    },
    logout: (state) => {
      localStorage.removeItem("JCI_ADMIN_AUTH_USER_DATA");
      state.user = null;
    },
  },
});

export const { login, logout } = auth.actions;

export default auth.reducer;
