import { useQuery } from "@tanstack/react-query";
import { getRoles } from "api/admin";
import React, { useState } from "react";
import { Button, Form, FormGroup, FormLabel, ModalBody, ModalFooter, ModalHeader, NavLink } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { Input, Modal } from "reactstrap";

export default function EditAdmin({ admin, onClose, onSave }) {
  const query = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  const [data, setData] = useState({
    ...admin,
    role: admin?.role?._id,
  });

  return (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader>Create Admin</ModalHeader>

      <ModalBody>
        <FormGroup className="mb-2">
          <FormLabel for="name">Name</FormLabel>
          <Input type="text" id="name" value={data?.name} onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))} placeholder="Enter name..." />
        </FormGroup>
        <FormGroup className="mb-2">
          <FormLabel for="email">Email</FormLabel>
          <Input disabled type="text" id="email" value={data?.email} onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))} placeholder="Enter email..." />
        </FormGroup>
        <FormGroup className="mb-2">
          <FormLabel for="password">Password</FormLabel>
          <Input type="password" id="password" value={data?.password} onChange={(e) => setData((prev) => ({ ...prev, password: e.target.value }))} placeholder="Enter password..." />

          <Form.Text id="passwordHelpBlock" muted>
            Please enter a new password if you wish to update the current one.
          </Form.Text>
        </FormGroup>

        <FormGroup className="mb-2">
          <FormLabel for="password">Role</FormLabel>
          <Form.Select aria-label="Default select example" onChange={(e) => setData((prev) => ({ ...prev, role: e.target.value }))}>
            {query?.data?.data?.roles?.map((role) => (
              <option value={role?._id} selected={data?.role == role?._id}>
                {role?.name}
              </option>
            ))}
          </Form.Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button size="sm" variant="danger" onClick={onClose}>
          Discard
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            console.log(data.role);

            if (data?.name == "" || data?.email == "" || data.password == "") {
              NotificationManager.info("All fields are required.");
            } else {
              onSave({
                ...data,
                role: data?.role != "" ? data?.role : query?.data?.data?.roles[0]._id,
              });
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
