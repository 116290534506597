import React, { useState } from "react";
import { Button, FormGroup, FormLabel, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { Input, Modal } from "reactstrap";

export default function CreateRole({ onClose, onSave }) {
  const [name, setName] = useState("");
  return (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader>Add New Role</ModalHeader>

      <ModalBody>
        <FormGroup>
          <FormLabel for="name">Name</FormLabel>
          <Input value={name} onChange={(e) => setName(e.target.value)} type="text" id="name" placeholder="Enter name..." />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button size="sm" variant="danger" onClick={onClose}>
          Discard
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            onSave(name);
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
