import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Alert } from "react-bootstrap";
import { getStats } from "api/dashboard";
import { useHistory } from "react-router-dom";
import LastTenDayUserRegistrationChart from "components/chart/LastTenDayUserRegistrationChart";
import LastTenDayCheckedInChart from "components/chart/LastTenDayCheckedInChart";
import FreeAndPremiumUsersChart from "components/chart/FreeAndPremiumUsersChart";
import AndroidAndIosChart from "components/chart/AndroidAndIosChant";
import { Can } from "components/can";

function Dashboard() {
  const [stats, setStats] = useState({
    totalRegisteredUsers: 0,
    totalInactiveUsers: 0,
    totalActiveUsers: 0,
    totalPremiumUsers: 0,
    totalCheckIns: 0,
    last24HoursMissedCheckIns: 0,
    last24HoursCheckIns: 0,
    emailSentInLast24Hours: 0,
    smsSentInLast24Hours: 0,
    averageCheckInsPerUser: 0,
    percentageOfUserWhoCheckedInDaily: 0,
    numberOfEmergencyAlertSend: 0,
  });

  const history = useHistory();

  const loadStats = async () => {
    try {
      const { data } = await getStats();

      setStats(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    loadStats();
  }, []);

  return (
    <>
      <Container fluid>
        <Can I="read" this="Stats">
          <Row>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-02 text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Registered users</p>
                        <Card.Title as="h4">{stats?.totalRegisteredUsers}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("users")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-02 text-info"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Free users</p>
                        <Card.Title as="h4">{stats?.totalRegisteredUsers - stats?.totalPremiumUsers}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("free-users")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-circle-09 text-success"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Premium users</p>
                        <Card.Title as="h4">{stats?.totalPremiumUsers}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("premium-users")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-light-3 text-success"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Check In</p>
                        <Card.Title as="h4">{stats?.totalCheckIns}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("check-ins")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-ambulance text-danger"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Last 24 hours missed check-in</p>
                        <Card.Title as="h4">{stats?.last24HoursMissedCheckIns}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("last-24-hours-missed-check-in-users")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-check-2 text-info"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Last 24 hours check-in</p>
                        <Card.Title as="h4">{stats?.last24HoursCheckIns}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("last-24-hours-check-ins")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chat-round text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">SMS send in last 24 hours</p>
                        <Card.Title as="h4">{stats?.smsSentInLast24Hours}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("last-24-hours-sms")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart-pie-36 text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Average Daily Check-Ins per User</p>
                        <Card.Title as="h4">{Math.round(stats?.averageCheckInsPerUser)}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => {}}>
                    <i className="fas fa-redo me-1"></i>
                    Update Now
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-chart-pie-35 text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Percentage of Users Who Have Checked In Daily</p>
                        <Card.Title as="h4">{Math.round(stats?.percentageOfUserWhoCheckedInDaily)}%</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => {}}>
                    <i className="fas fa-redo me-1"></i>
                    Update Now
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-ambulance text-warning"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Number of Emergency Alerts Sent</p>
                        <Card.Title as="h4">{stats?.numberOfEmergencyAlertSend}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }} onClick={() => history.push("emergency-alerts")}>
                    <i className="fas fa-list me-1"></i>
                    See Details
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-danger">
                        <i className="fas fa-minus text-danger"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Inactive User</p>
                        <Card.Title as="h4">{stats?.totalInactiveUsers}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }}>
                    <i className="fas fa-redo me-1"></i>
                    Update Now
                  </div>
                </Card.Footer>
              </Card>
            </Col>

            <Col lg="3" sm="6">
              <Card className="card-stats">
                <Card.Body>
                  <Row>
                    <Col xs="5">
                      <div className="icon-big text-center icon-success">
                        <i className="fas fa-plus text-success"></i>
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Active User</p>
                        <Card.Title as="h4">{stats?.totalActiveUsers}</Card.Title>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <hr></hr>
                  <div className="stats" style={{ cursor: "pointer" }}>
                    <i className="fas fa-redo me-1"></i>
                    Update Now
                  </div>
                </Card.Footer>
              </Card>
            </Col>
            <hr />
            <Col md={6} sm={12}>
              <LastTenDayCheckedInChart />
            </Col>
            <Col md={6} sm={12}>
              <LastTenDayUserRegistrationChart />
            </Col>
            <Col md={6} sm={12}>
              <FreeAndPremiumUsersChart />
            </Col>
            <Col md={6} sm={12}>
              <AndroidAndIosChart />
            </Col>
          </Row>
        </Can>

        <Can not I="read" this="Stats">
          <Alert variant="danger">You do not have proper permission to access this information.</Alert>
        </Can>
      </Container>
    </>
  );
}

export default Dashboard;
