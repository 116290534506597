import api from ".";

export const getFeedbackEmails = () => {
  return api.get("/feedback/emails");
};

export const setFeedbackEmails = (emails) => {
  return api.post("/feedback/emails", { emails });
};

export const getFeedbacks = (params) => {
  return api.get("/feedback", {
    params,
  });
};

export const getFeedbackMessages = (id) => {
  return api.get(`/feedback/${id}/messages`, {
    params: {
      limit: 500,
    },
  });
};

export const doFeedbackMessages = (id, text) => {
  return api.post(`/feedback/${id}/messages`, { text });
};

export const updateFeedbackStatus = (id, status) => {
  return api.patch(`/feedback/${id}`, { status });
};

export const deleteFeedback = (id) => {
  return api.delete(`/feedback/${id}`);
};
