import { updateFaq } from "api/faq";
import { deleteFaq } from "api/faq";
import { createFAQ } from "api/faq";
import { getFAQs } from "api/faq";
import { Can } from "components/can";
import CreateFaqModal from "components/faq/CreateFaqModal";
import EditModal from "components/faq/EditModal";
import React, { useEffect } from "react";
import { useState } from "react";

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Spinner, Accordion, Alert } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function FAQs() {
  // const [premium, setPremium] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [edit, setEdit] = useState();

  const loadMoreUsers = async (skip, limit = 20) => {
    try {
      setHasMore(true);

      const { data } = await getFAQs(skip || users.length, limit);

      console.log(data.FAQs.length);
      setUsers((prev) => [...prev, ...data.FAQs]);
      setHasMore(data.FAQs.length === 20);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadMoreUsers();
  }, []);

  const handleUpdate = async (faq) => {
    try {
      const { data } = await updateFaq(faq);

      console.log(data.FAQ);

      setUsers((prev) =>
        prev.map((u) => {
          if (u._id === data?.FAQ?._id) u = data.FAQ;
          return u;
        })
      );

      NotificationManager.success("Successfully updated.");
    } catch (error) {
      console.log(error);

      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  const handleCreate = async (faq) => {
    try {
      const { data } = await createFAQ(faq);

      setUsers((prev) => [data?.FAQ, ...prev]);

      NotificationManager.success("Successfully Created.");
    } catch (error) {
      console.log(error);

      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
    }
  };

  const handleDelete = async (faq) => {
    try {
      if (confirm("The faq will be deleted permanently. You can't undo this action.")) {
        setUsers((prev) => prev.filter((u) => u._id !== faq._id));

        await deleteFaq(faq._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [create, setCreate] = useState(false);

  return (
    <>
      <Container fluid>
        <EditModal edit={edit} setEdit={setEdit} handleUpdate={handleUpdate} />
        <CreateFaqModal edit={create} setEdit={setCreate} handleUpdate={handleCreate} />

        <Can I="create" this="FAQ">
          <Container className="text-end mb-4">
            <Button size="sm" onClick={() => setCreate({ title: "", content: "" })}>
              Add
            </Button>
          </Container>
        </Can>

        <Can I="read" this="FAQ">
          <Accordion defaultActiveKey={0}>
            {users.map((u, idx) => (
              <Accordion.Item key={idx} eventKey={idx}>
                <Accordion.Header>{u?.title}</Accordion.Header>
                <Accordion.Body
                  dangerouslySetInnerHTML={{
                    __html: u?.content,
                  }}
                ></Accordion.Body>

                <Accordion.Body className="text-end">
                  <Can I="delete" this="FAQ">
                    <Button size="sm" onClick={() => handleDelete(u)} variant="danger" className="me-2">
                      Delete
                    </Button>
                  </Can>
                  <Can I="update" this="FAQ">
                    <Button size="sm" onClick={() => setEdit(u)}>
                      Edit
                    </Button>
                  </Can>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Can>

        <Can not I="read" this="FAQ">
          <Alert variant="danger">You do not have proper permission to access this information.</Alert>
        </Can>
      </Container>
    </>
  );
}

export default FAQs;
