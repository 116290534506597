import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { placeholder } from "./SendEmail";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { createEmailTemplate } from "api/email";
import { getEmailTemplates } from "api/email";

export default function CreateEmailTemplateModal({ show, onCreate, onClose }) {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const reset = () => {
    setName("");
    setSubject("");
    setContent("");
  };

  useEffect(reset, [show]);

  const handleSave = () => {
    if (name == "") {
      NotificationManager.error("Notification name is required.");
    } else if (subject == "") {
      NotificationManager.error("Notification subject is required.");
    } else if (content == "") {
      NotificationManager.error("Notification content is required.");
    } else {
      onCreate(name, subject, content);
    }
  };

  return (
    <>
      <Modal isOpen={show} toggle={() => onClose(null)} size="lg" centered>
        <ModalHeader>Create Template</ModalHeader>

        <ModalBody>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Template Name</Form.Label>
            <Form.Control value={name} onChange={(e) => setName(e.target.value)} type="email" placeholder="Enter template name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Subject</Form.Label>
            <Form.Control value={subject} onChange={(e) => setSubject(e.target.value)} type="email" placeholder="Enter email subject" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Content/html</Form.Label>

            <CodeEditor
              value={content}
              language="html"
              placeholder={placeholder}
              onChange={(e) => setContent(e.target.value)}
              padding={15}
              style={{
                overflowY: "auto",
                height: 350,
                fontSize: 12,
                backgroundColor: "#000",
                fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              }}
            />
          </Form.Group>
        </ModalBody>

        <ModalFooter>
          <div className="d-flex justify-content-between w-100">
            <Button variant="dark" onClick={() => onCreate(null)} size="sm" className="me-2">
              Close
            </Button>

            <Button variant="primary" onClick={handleSave} size="sm" className="me-2">
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}
