import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Input, Modal } from "reactstrap";
import { getActivities } from "../../api/users";
export default function ActivityModal({ user, onClose }) {
  const [activities, setActivities] = useState([]);

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (user) {
      loadActivities();
    } else {
      setHasMore(true);
      setActivities([]);
    }
  }, [user]);

  const loadActivities = async () => {
    try {
      const { data } = await getActivities(user, activities.length, 20);

      setHasMore(data?.activities.length === 20);

      setActivities((prev) => [...prev, ...data.activities]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal isOpen={Boolean(user)} toggle={() => setEdit(null)}>
      <ModalHeader>Activity</ModalHeader>

      <ModalBody
        style={{ maxHeight: 300, overflowY: "scroll" }}
        id="activities"
      >
        {user && (
          <InfiniteScroll
            dataLength={activities.length} //This is important field to render the next data
            next={loadActivities}
            hasMore={hasMore}
            loader={
              <div className="text-center py-4">
                <Spinner animation="grow" />
              </div>
            }
            scrollableTarget="activities"
          >
            {activities.map((i, idx) => (
              <div className="d-flex border-bottom pb-2 mb-2" key={idx}>
                <div className="pe-3">
                  {moment(i.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                </div>
                <code>{i?.details}</code>
              </div>
            ))}
          </InfiniteScroll>
        )}

        {!hasMore && activities.length === 0 && <h5>No Activity Found.</h5>}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-center">
        <Button color="secondary" size="sm" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
