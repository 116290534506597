import React from "react";
import { Button, Form, FormGroup, FormLabel, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { Input, Modal } from "reactstrap";

export default function EditModal({ edit, setEdit, handleUpdate }) {
  return (
    <Modal isOpen={edit} toggle={() => setEdit(null)}>
      <ModalHeader>Edit</ModalHeader>

      <ModalBody>
        <FormGroup>
          <FormLabel for="exampleEmail">Name</FormLabel>
          <Input
            type="text"
            value={edit?.name}
            name="email"
            id="exampleEmail"
            placeholder="Enter name..."
            onChange={(e) =>
              setEdit({
                ...edit,
                name: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel for="exampleEmail">Email</FormLabel>
          <Input
            type="email"
            value={edit?.email}
            name="email"
            id="exampleEmail"
            placeholder="Enter email address..."
            disabled
            onChange={(e) =>
              setEdit({
                ...edit,
                email: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel for="exampleEmail">Phone Number</FormLabel>
          <Input
            type="text"
            value={edit?.phoneNumber}
            name="email"
            id="exampleEmail"
            placeholder="Enter phone number..."
            onChange={(e) =>
              setEdit({
                ...edit,
                phoneNumber: e.target.value,
              })
            }
          />
        </FormGroup>

        <FormGroup>
          <FormLabel for="exampleEmail">Account Type</FormLabel>

          <Form.Check
            checked={edit?.isPurchased}
            onChange={() => {
              setEdit((prev) => ({
                ...prev,
                isPurchased: true,
              }));
            }}
            type={"radio"}
            label={`Premium`}
            id="premium"
            name="p"
          />
          <Form.Check
            checked={!edit?.isPurchased}
            onChange={() => {
              setEdit((prev) => ({
                ...prev,
                isPurchased: false,
              }));
            }}
            type={"radio"}
            label={`Free`}
            id="free"
            name="p"
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={() => setEdit(null)}>
          Discard
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setEdit(null);
            handleUpdate(edit);
          }}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
}
