function isCheckedIn(lastCheckInTime = 0) {
  // Get the current time in milliseconds
  const currentTime = Date.now();

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime - lastCheckInTime;

  // Define a threshold for one day in milliseconds (24 hours)
  const oneDayThreshold = 24 * 60 * 60 * 1000;

  // Check if lastCheckInTime is less than one day ago or not checked in at all
  if (timeDifference < oneDayThreshold || timeDifference < 0) {
    return "Yes";
  } else {
    return "No";
  }
}

function isActiveAccount(lastCheckInTime = Date.now()) {
  const lastCheckedInTime = new Date(lastCheckInTime);

  const currentDate = new Date();

  const timeDifference = currentDate - lastCheckedInTime;

  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  if (daysDifference >= 3) {
    return "No";
  } else {
    return "Yes";
  }
}

const getStatusColor = (status) => {
  switch (status) {
    case "submitted":
      return "blue";
    case "in-progress":
      return "#00ff00";
    case "open":
      return "#0000ff";
    case "on-hold":
      return "#FFD700";
    case "solved":
      return "#00ff00";
    case "closed":
      return "#ff0000";
    default:
      return "#000000";
  }
};

export { isCheckedIn, isActiveAccount, getStatusColor };
