import api from ".";

export const getQuotes = () => {
  return api.get("/quotes");
};

export const updateQuote = (quote) => {
  return api.put(`/quotes/${quote._id}`, quote);
};

export const addQuote = (quote) => {
  return api.post(`/quotes`, quote);
};

export const deleteQuote = (id) => {
  return api.delete(`/quotes/${id}`);
};
