import moment from "moment";
import React from "react";

export default function Message({ message: { text, createdAt, user } }) {
  const self = user == null;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: self ? "flex-end" : "flex-start",
      }}
    >
      <div className={"text-start"}>
        <div
          style={{
            background: self ? "#003f99" : "#f1f1f1",
            color: self ? "#fff" : "#000",
            padding: "4px 8px",
            borderRadius: self ? "20px 20px 0px 20px" : "20px 20px 20px 0",
            margin: 0,
            whiteSpace: "pre-wrap",
          }}
        >
          {text || "..."}
        </div>

        <small className="m-0 text-muted" style={{ fontSize: 10 }}>
          {moment(createdAt).fromNow()}
        </small>
      </div>
    </div>
  );
}
