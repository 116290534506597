import { login as adminLogin } from "api/auth";
import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { login } from "store/slice/auth";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { loginWithEmailPassword } from "api/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "just-checking-in-bitrupt.firebaseapp.com",
  databaseURL: "https://just-checking-in-bitrupt.firebaseio.com",
  projectId: "just-checking-in-bitrupt",
  storageBucket: "just-checking-in-bitrupt.appspot.com",
  messagingSenderId: "708934845319",
  appId: "1:708934845319:web:b45b1baba9a92de6908922",
  measurementId: "G-151M0D36BR",
};
// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
// const auth = getAuth(app);

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);

      const { data } = await loginWithEmailPassword(email, password);

      dispatch(login({ ...data?.user, jwt: data?.token }));
    } catch (error) {
      setLoading(false);

      NotificationManager.error(
        error?.response?.data?.issue?.message || error.message
      );
    }
  };

  return (
    <div
      className="bg-light d-flex justify-content-center align-items-center"
      style={{
        height: "100vh",
      }}
    >
      <Container>
        <Row className="py-5 justify-content-center">
          <Col sm={12} md={6} lg={4}>
            <Card className="shadow-sm">
              <Card.Header>
                <Card.Title as="h4">Login</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleLogin}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <div className="text-center">
                    <Button
                      variant="secondary"
                      type="submit"
                      style={{
                        minWidth: 120,
                      }}
                    >
                      {isLoading && <Spinner animation="grow" size="sm" />}{" "}
                      Login
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
