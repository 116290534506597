import { useQuery } from "@tanstack/react-query";
import { getFeedbackMessages } from "../api/feedback";

const useFeedbackMessage = (id) => {
  const queryKey = ["getFeedbackMessage", id];

  return useQuery({ queryKey, queryFn: () => getFeedbackMessages(id) });
};

export default useFeedbackMessage;
