import React from "react";
import { Button, Form, FormGroup, FormLabel, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { Input, Modal } from "reactstrap";
import ReactQuill from "react-quill";
export default function CreateFaqModal({ edit, setEdit, handleUpdate }) {
  return (
    <Modal isOpen={Boolean(edit)} toggle={() => setEdit(null)}>
      <ModalHeader>Edit FAQ</ModalHeader>

      <ModalBody>
        <FormGroup>
          <FormLabel for="exampleEmail">Question</FormLabel>
          <Input
            type="text"
            value={edit?.title}
            name="email"
            id="exampleEmail"
            placeholder="Enter question..."
            onChange={(e) =>
              setEdit({
                ...edit,
                title: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel for="exampleEmail">Content</FormLabel>

          {Boolean(edit) && (
            <ReactQuill
              theme="snow"
              value={edit?.content}
              onChange={(e) =>
                setEdit({
                  ...edit,
                  content: e,
                })
              }
            />
          )}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={() => setEdit(null)}>
          Discard
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setEdit(null);
            handleUpdate(edit);
          }}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
}
