import React from "react";
import {
  Button,
  Form,
  FormGroup,
  FormLabel,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { Input, Modal } from "reactstrap";

export default function AddQuoteModal({ quote, setQuote, handleSave }) {
  return (
    <Modal isOpen={Boolean(quote)} toggle={() => setQuote(null)}>
      <ModalHeader>Add Quote</ModalHeader>

      <ModalBody>
        <FormGroup className="mb-2">
          <FormLabel for="exampleEmail">Author</FormLabel>
          <Input
            type="text"
            value={quote?.author}
            name="email"
            id="exampleEmail"
            placeholder="Enter author name..."
            onChange={(e) =>
              setQuote({
                ...quote,
                author: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="mb-2">
          <FormLabel for="content">Content</FormLabel>

          <textarea
            style={{
              width: "100%",
            }}
            className="p-2 border"
            rows={2}
            value={quote?.content}
            onChange={(e) =>
              setQuote({
                ...quote,
                content: e.target.value,
              })
            }
          ></textarea>
        </FormGroup>

        <FormGroup>
          <FormLabel for="p">Status</FormLabel>

          <Form.Check
            checked={quote?.active}
            onChange={() => {
              setQuote((prev) => ({
                ...prev,
                active: !prev.active,
              }));
            }}
            type={"radio"}
            label={`Active`}
            id="active"
            name="p"
          />
          <Form.Check
            checked={!quote?.active}
            onChange={() => {
              setQuote((prev) => ({
                ...prev,
                active: !prev.active,
              }));
            }}
            type={"radio"}
            label={`Inactive`}
            id="inactive"
            name="p"
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={() => setQuote(null)}>
          Discard
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setQuote(null);
            handleSave(quote);
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  );
}
